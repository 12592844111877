import { cva } from 'class-variance-authority';

const root = cva([
  'flex flex-col w-[215px] max-w-[215px] h-full gap-200 bg-secondary rounded-md overflow-y-scroll no-scrollbar flex-shrink-0',
]);

const banner = {
  container: cva([
    'rounded-2xl',
    'bg-no-repeat bg-cover bg-center overflow-hidden',
    'flex-shrink-0',
    'dark',
  ]),
  content: {
    wrapper: cva(['flex gap-50 items-center flex-shrink-0 w-full flex-1', 'pb-100 pr-100 pl-200'], {
      variants: {
        withImage: {
          true: 'pt-800 bg-gradient-to-b from-grayA3 to-grayA11',
          false: 'pt-100 bg-tertiary',
        },
      },
      defaultVariants: {
        withImage: false,
      },
    }),
    menu: {
      trigger: cva([
        'bg-transparent text-primary border-none w-button-sm hover:bg-[rgba(255,255,255,10%)] transition-bg !px-0',
      ]),
      content: {
        wrapper: cva([
          'border-select-list will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade min-w-[200px] z-30',
        ]),
        item: cva([''], {
          variants: {
            variant: {
              default: '',
              destructive:
                'text-select-hint-error hover:bg-select-list-item-destructive-hover active:bg-select-list-item-destructive-press',
            },
          },
          defaultVariants: {
            variant: 'default',
          },
        }),
      },
      separator: cva(['h-12 my-50', 'bg-[rgba(255,255,255,10%)]']),
    },
  },
};

const user = {
  container: cva(['bg-tertiary rounded-3xl flex flex-col py-150 px-200 gap-200']),
  avatar: cva([]),
  name: cva([]),
  menu: {
    content: {
      wrapper: cva(['rounded-select-list-md border border-[rgba(255,255,255,0.05)] min-w-[200px]']),
      separator: cva(['h-12', 'bg-[rgba(255,255,255,10%)]']),
    },
  },
};

export const styles = {
  root,
  banner,
  user,
};
