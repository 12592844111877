import jar from 'js-cookie';

const authenticatedRoutes = ['/my-communities'];

export const signOut = () => {
  const pathname = window.location.pathname;
  jar.remove('user_metadata', { domain: `.${window.location.hostname}` });
  jar.remove('user_metadata', { domain: window.location.hostname });
  if (pathname && authenticatedRoutes.includes(pathname)) window.location.href = '/';
  else window.location.reload();
};
