'use client';

import { Avatar, ProgressBarAnatomy as P, Skeleton } from '@zealy/design-system';
import React, { useMemo } from 'react';
import { getLevelInfo, roleIsAtLeast } from '@zealy/utils';

import type { CommunityMember, } from '@zealy/queries';
import Link from 'next/link';
import { UserDropdown } from './UserDropdown';
import { cn } from '#utils/utils';
import { styles } from '../Sidebar.styles';
import { useTranslations } from 'next-intl';

export function UserCard({
  user,
  subdomain,
  className,
}: {
  user: CommunityMember;
  subdomain: string;
  className?: string;
}) {
  const t = useTranslations('sidebar');

  const [baseXP, userLevel, maxXP] = useMemo(() => getLevelInfo(user?.xp ?? 0), [user?.xp]);

  return (
    <div className={cn(styles.user.container(), className)}>
      <div className="flex justify-between items-center gap-50">
        <Link
          href={user.role ? `/cw/${subdomain}/users/${user?.id}` : '#'}
          className="flex items-center gap-100"
        >
          <Avatar size="xs" name={user.name ?? ''} src={user.avatar} />
          <span className="text-primary text-sm truncate max-w-[80px]">{user.name}</span>
        </Link>
        {user.isMe && (
          <UserDropdown
            {...{
              user,
              subdomain,
            }}
          />
        )}
      </div>
      {user.role && (!roleIsAtLeast(user.role, 'editor') || user.xp > 0) && (
        <P.Root
          data-testid="progressbar"
          fillShadowBg="var(--color-bg-tertiary)"
          variant={'brand'}
          size={'sm'}
          max={maxXP}
          value={baseXP}
        >
          <P.LabelGroup>
            <P.Label data-testid="userlevel">
              <span className="text-primary text-xs">
                {t('settings.level')} {userLevel}
              </span>
            </P.Label>
            <P.Label data-testid="userxp">
              {baseXP} / {maxXP} {t('settings.xp')}
            </P.Label>
          </P.LabelGroup>
          <P.Track />
        </P.Root>
      )}
    </div>
  );
}

export const UserCardSkeleton = ({ className = '' }) => (
  <div className={cn(styles.user.container(), className)}>
    <div className="flex justify-between items-center gap-50">
      <div className="flex items-center gap-100">
        <Skeleton className="h-avatar-xs w-avatar-xs" />
        <Skeleton className="h-[16px] w-800" />
      </div>
    </div>

    <div className="flex gap-progress-bar-sm-y flex-col">
      <div className="flex justify-between">
        <Skeleton className="h-[10px] w-400" />
        <Skeleton className="h-[10px] w-400" />
      </div>
      <Skeleton className="h-progress-bar-sm w-full" />
    </div>
  </div>
);
