import { useRouter } from 'next/navigation';

import { signOut } from '#utils/signout';

export const useNavbarActions = () => {
  const router = useRouter();

  return {
    signOut,
    openSettings: () => {
      router.push('/cw/_/settings/profile');
    },
  };
};
